import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {safePrefix} from '../utils';
import Header from './Header';
import Footer from './Footer';

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0" />
                    <meta name="google" content="notranslate" />
                     
                    
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://covid19.cmladvocates.net" />
                    <meta property="og:title" content="Chronic Myeloid Leukemia (CML) & COVID-19 Information" />
                    <meta property="og:description" content="Resources for CML patients and caregivers." />
                    <meta property="og:image" content="https://covid19.cmladvocates.net/images/rich_preview_3.jpg" />
                    <meta property="og:image:type" content="image/jpg" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                        
                    <meta property="og:image" content="https://covid19.cmladvocates.net/images/WhatsApp.jpg" />
                    <meta property="og:image:type" content="image/jpg" />
                    <meta property="og:image:width" content="300" />
                    <meta property="og:image:height" content="300" />
                    
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:url" content="https://covid19.cmladvocates.net" />
                    <meta property="twitter:site" content="@cmlnet" />
                    <meta property="twitter:title" content="Latest CML and COVID-19 information" />
                    <meta property="twitter:description" content="@cmlnet have created this webpage to keep track of CML/COVID-19 information for patients and caregivers living with CML" />
                    <meta property="twitter:image" content="https://covid19.cmladvocates.net/images/twitter_summary.jpg" />    
                        
                    <link href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i%26Display=swap" rel="stylesheet"/> 
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')}/>
                    <link rel="icon" href={safePrefix('images/favicon.ico?v=2')} type="image/x-icon" />
                </Helmet>
                <div id="page" className="site">
                  <Header {...this.props} />
                  <main id="content" className="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                </div>
            </React.Fragment>
        );
    }
}
